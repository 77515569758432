import { Injectable } from "@angular/core";
import { AppService } from '@services/app.service';


@Injectable()
export class AuthenticationService extends AppService {

    login(username: string, password: string) {
        // this.showLoader();
        // let hashPass = CryptoJS.HmacSHA256(password, '652ahscamHtset').toString();
        let data = {
            username: username,
            pass: password
        }
        data = this.encryption(data)
        return this.flexHttp.post({
            autoParseFlexData: false,
            url: '/hq/login',
            body: {
                data: data
            },
            successMap: (response) => {
                let token = this.decryption(response.data);
                if (token) {
                    this.setToken(token)
                    this.initFlexHttp();
                }
                // this.hideLoader();
                return response;
            }
        })
    }

    forgetPassword(email) {
        this.showLoader();
        let data = {
            email: email,
        }
        data = this.encryption(data)
        return this.flexHttp.post({
            url: `/hq/user/forget-password/email`,
            body: {
                data: data
            },
            successMap: (response) => {
                this.hideLoader();
                return this.decryption(response);
            },
            errorMap: (err) => {
                this.hideLoader();
            }
        })
    }

    verify(session) {
        return this.flexHttp.post({
            url: `/hq/user/forget-password/verify`,
            body: {
                data: session
            },
            successMap: (response) => {
                return this.decryption(response);
            }
        })
    }

    resetPassword(data) {
        data = this.encryption(data)
        return this.flexHttp.post({
            url: `/hq/user/forget-password/reset`,
            body: {
                data: data
            },
            successMap: (response) => {
                return this.decryption(response);
            }
        })
    }

}