<!-- BEGIN: Header -->
<header
  id="m_header"
  class="m-grid__item m-header"
  m-minimize-offset="200"
  m-minimize-mobile-offset="200"
  appunwraptag=""
>
  <div class="m-container m-container--fluid m-container--full-height">
    <div class="m-stack m-stack--ver m-stack--desktop">
      <!-- BEGIN: Brand -->
      <div class="m-stack__item m-brand m-brand--skin-dark">
        <div class="m-stack m-stack--ver m-stack--general">
          <div class="m-stack__item m-stack__item--middle m-brand__logo">
            <a class="m-brand__logo-wrapper" routerLink="/index">
              <h5 class="text-white mb-0">V-Office</h5>
            </a>
          </div>

          <div class="m-stack__item m-stack__item--middle m-brand__tools">
            <!-- BEGIN: Left Aside Minimize Toggle -->
            <a
              href="javascript:;"
              id="m_aside_left_minimize_toggle"
              class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
            >
              <span></span>
            </a>
            <!-- END -->

            <!-- BEGIN: Responsive Aside Left Menu Toggler -->
            <a
              href="javascript:;"
              id="m_aside_left_offcanvas_toggle"
              (click)="mAsideToggle()"
              class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
            >
              <span></span>
            </a>
            <!-- END -->

            <!-- BEGIN: Topbar Toggler -->
            <a
              id="m_aside_header_topbar_mobile_toggle"
              href="javascript:;"
              class="m-brand__icon m--visible-tablet-and-mobile-inline-block"
            >
              <i class="flaticon-more"></i>
            </a>
            <!-- BEGIN: Topbar Toggler -->
          </div>
        </div>
      </div>
      <!-- END: Brand -->
      <div
        class="m-stack__item m-stack__item--fluid m-header-head"
        id="m_header_nav"
      >
        <!-- BEGIN: Horizontal Menu -->
        <button
          class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-dark"
          id="m_aside_header_menu_mobile_close_btn"
        >
          <i class="la la-close"></i>
        </button>
        <div
          id="m_header_menu"
          class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
        >
          <ul class="m-menu__nav m-menu__nav--submenu-arrow"></ul>
        </div>
        <!-- END: Horizontal Menu -->

        <!-- BEGIN: Topbar -->
        <div
          id="m_header_topbar"
          class="m-topbar m-stack m-stack--ver m-stack--general"
          *ngIf="userDetails"
        >
          <div class="m-stack__item m-topbar__nav-wrapper">
            <ul class="m-topbar__nav m-nav m-nav--inline">
              <li
                class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                data-dropdown-toggle="click"
              >
                <a href="#" class="m-nav__link m-dropdown__toggle">
                  <div class="topbar-item">
                    <div
                      class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
                      id="outer"
                    >
                      <div id="inner">
                        <span
                          class="text-muted font-weight-bold d-none d-md-inline mr-1"
                          >Hi,</span
                        >
                        <span
                          class="text-dark-50 font-weight-bold d-none d-md-inline"
                          >{{ userDetails?.name }}</span
                        >
                      </div>
                      <div
                        class="cube ml-4"
                        style="margin-right: -20px"
                        id="inner"
                      >
                        <span
                          class="symbol-label font-size-h5 font-weight-bold"
                          >{{ userDetails?.name.charAt(0) }}</span
                        >
                      </div>
                    </div>
                  </div>
                </a>

                <div class="m-dropdown__wrapper">
                  <span
                    class="m-dropdown__arrow m-dropdown__arrow--right"
                    style="color: white"
                  ></span>
                  <div class="m-dropdown__inner">
                    <div class="m-dropdown__header m--align-center">
                      <p
                        class="font-weight-bold m-0"
                        style="text-align: start; color: #3f4254"
                      >
                        User Profile
                      </p>
                      <p></p>
                      <p></p>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-100"></div>
                        <div class="d-flex flex-column">
                          <div
                            class="font-weight-bold font-size-h6 text-dark-75 text-primary left-align-text"
                            disabled
                          >
                            {{ userDetails?.name }}
                          </div>
                          <div class="text-muted mt-1 left-align-text">
                            <small>
                              {{ userDetails?.job_title_display }}
                            </small>
                          </div>

                          <div class="navi mt-2 left-align-text">
                            <div class="navi-item">
                              <span class="navi-link p-0 pb-2">
                                <span
                                  class="navi-text text-muted text-hover-primary"
                                >
                                  {{ userDetails?.email }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="m-dropdown__body">
                      <div class="m-dropdown__content">
                        <ul class="m-nav m-nav--skin-light">
                          <div class="row">
                            <div class="col-4">
                              <button
                                type="button"
                                class="btn btn-outline-primary btn-sm text-center"
                                style="
                                  margin-top: 8px;
                                  height: 30px;
                                  font-size: 12px;
                                  vertical-align: middle;
                                "
                                (click)="logout()"
                              >
                                {{ "Logout" | translate }}
                              </button>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li id="m_quick_sidebar_toggle" class="m-nav__item" hidden></li>
            </ul>
          </div>
        </div>
        <!-- END: Topbar -->
      </div>
    </div>
  </div>
</header>
<!-- END: Header -->
