import { Observable } from "rxjs/Rx";
import { Injectable } from "@angular/core";
import { AppService } from "@services/app.service";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(public _router: Router, private _app: AppService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    try {
      let path = route.url[0].path;
      let x = this._app.getCurrentUser().accessibility;
      for (let u of x) {
        if (path == u.path) {
          if (u.enabled) {
            return true;
          } else {
            this._router.navigate(["/"]);
            // this._app.showNotification('Access Denined', `${x['name']} (${x['role']})`, 'danger')
            return false;
          }
        }
      }
      this._router.navigate(["/"]);
      return false;
    } catch (e) {
      this._router.navigate(["/"]);
      // this._app.showNotification('Access Denined', 'Permission not granted', 'danger')
      return false;
    }
  }

  checkAccess() {
    let x = this._app.getCurrentUser();
    if (x.role.toUpperCase() === "ADMIN") {
      return true;
    } else {
      return false;
    }
  }
}
