import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { Helpers } from "./helpers";
import { TranslateService } from "@ngx-translate/core";
import { SwUpdate } from "@angular/service-worker";
import { AppService } from "@services/app.service";

@Component({
  selector: "body",
  templateUrl: "./app.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = "app";
  globalBodyClass = `m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark 
        m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default`;

  constructor(
    private _router: Router,
    private translate: TranslateService,
    private swUpdate: SwUpdate,
    private service: AppService
  ) {}

  ngOnInit() {
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
        Helpers.bodyClass(this.globalBodyClass);
      }
      if (route instanceof NavigationEnd) {
        Helpers.setLoading(false);
      }
    });
    this.setLanguage();

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        // if (confirm("This page is updated. The page will be refreshed.")) {
        this.service
          .swalNotification(
            "info",
            "This page has been updated",
            "Please reload it",
            true
          )
          .then((response) => {
            if (response.value) {
              window.location.reload();
            }
          });
      });
    }
  }

  setLanguage() {
    let lang = localStorage.getItem("language");
    if (lang) {
      this.translate.use(lang);
    } else {
      localStorage.setItem("language", "en");
      this.translate.use("en");
    }
  }
}
