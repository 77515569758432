<div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin" id="m_login">
	<div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
		<div class="m-stack m-stack--hor m-stack--desktop">
			<div class="m-stack__item m-stack__item--fluid">
				<div class="m-login__wrapper">
					<div class="m-login__logo">
						<a href="#">
							<img src="./assets/app/media/img/logos/fs.png">
						</a>
					</div>
					<div class="m-login__signin">
						<div class="m-login__head">
							<h3 class="m-login__title">
								{{ 'Reset Password' | translate }}
							</h3>
						</div>

						<div class="row m--margin-top-50" [formGroup]="form">
							<div class="col-12" [ngClass]="{'has-danger': form.get('newPassword').invalid && form.get('newPassword').touched}">
								<div class="m-input-icon m-input-icon--right">
									<input type="{{ showPass ? 'text':'password' }}" class="form-control m-input" placeholder="{{ 'Password' | translate }}" formControlName="newPassword">
									<span class="m-input-icon__icon m-input-icon__icon--right">
										<span class="cursor" (click)="showPassword()">
											<i *ngIf="showPass" class="la la-eye-slash"></i>
											<i *ngIf="!showPass" class="la la-eye"></i>
										</span>
									</span>
								</div>
								<span class="form-control-feedback" *ngIf="form.hasError('required', 'newPassword') && form.get('newPassword').touched">
									{{ 'This field is required' | translate }}
								</span>
								<span class="form-control-feedback" *ngIf="form.hasError('minlength', 'newPassword') && form.get('newPassword').touched && !form.hasError('required', 'newPassword')">
									{{ 'Password must be of minimum 6 characters length' | translate }}
								</span>
							</div>

							<div class="col-12 m--margin-top-20" [ngClass]="{'has-danger': form.get('newPasswordAgain').invalid && form.get('newPasswordAgain').touched}">
								<div class="m-input-icon m-input-icon--right">
									<input type="{{ showConPass ? 'text':'password' }}" class="form-control m-input" placeholder="{{ 'Confirm Password' | translate }}" formControlName="newPasswordAgain">
									<span class="m-input-icon__icon m-input-icon__icon--right">
										<span class="cursor" (click)="showConfirmPassword()">
											<i *ngIf="showConPass" class="la la-eye-slash"></i>
											<i *ngIf="!showConPass" class="la la-eye"></i>
										</span>
									</span>
								</div>
								<span class="form-control-feedback" *ngIf="form.get('newPasswordAgain').errors?.required && form.get('newPasswordAgain').touched">
									{{ 'This field is required' | translate }}
								</span>
								<span class="form-control-feedback" *ngIf="!form.get('newPasswordAgain').errors?.required && form.get('newPasswordAgain').errors?.doNotMatch && form.get('newPasswordAgain').touched">
									{{ 'Password do not match' | translate }}
								</span>
							</div>

							<div class="col-12 m--margin-top-30 m--align-center">
								<button class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air" (click)="resetPassword()">
									{{ 'Submit' | translate }}
								</button>
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1 m-login__content boxShadow" 
			style="background-image: url(./assets/app/media/img/bg/bg-4.jpg); position:relative;">
		<div class="m-grid__item m-grid__item--middle">
			<h3 class="m-login__welcome">
				Your trusted Business IT Partner.
			</h3>
			<p class="m-login__msg">
				We aspire to be Asia’s most innovative retail solution provider and a trusted IT partner of SMEs.
			</p>
		</div>
		<div class="company w-100 m--hidden-tablet-and-mobile">
			<ng-container *ngTemplateOutlet="companyInfo"></ng-container>
		</div>
	</div>
</div>

<ng-template #companyInfo>
	<p>140 Paya Lebar Road #05-18/25 AZ @ PAYA LEBAR Singapore 409015</p>
	<p>Office (65) 63846598 | www.flex-solver.com.sg</p>
	<p style="font-size: 0.85rem">&copy; 2018. All Rights Reserved by Flex-Solver.</p>
</ng-template>