import { NgModule } from "@angular/core";
import { ThemeComponent } from "./theme.component";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../auth/_guards/auth.guard";
import { RoleGuard } from "app/auth/_guards/role.guard";

const routes: Routes = [
  {
    path: "",
    component: ThemeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "index",
        loadChildren: "./pages/index/index.module#IndexModule",
      },
      {
        path: "management",
        loadChildren: "./pages/management/management.module#ManagementModule",
      },
      {
        path: "asset",
        loadChildren: "./pages/asset/asset.module#AssetModule",
      },
      {
        path: "attendance",
        loadChildren: "./pages/attendance/attendance.module#AttendanceModule",
      },

      {
        path: "journal",
        loadChildren: "./pages/journal/journal.module#JournalModule",
      },
      {
        path: "expense",
        loadChildren: "./pages/expense/expense.module#ExpenseModule",
      },
      {
        path: "booking",
        loadChildren: "./pages/booking/booking.module#BookingModule",
      },
      {
        path: "leave",
        loadChildren: "./pages/leave/leave.module#LeaveModule",
      },
      {
        path: "leave_status",
        loadChildren: "./pages/leave/leave-status.module#LeaveStatusModule",
      },
      {
        path: "404",
        loadChildren: "./pages/not-found/not-found.module#NotFoundModule",
      },
      {
        path: "inventory",
        loadChildren: "./pages/inventory/inventory.module#InventoryModule",
      },
      {
        path: "dashboard",
        loadChildren: "./pages/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "customer",
        loadChildren: "./pages/customer/customer.module#CustomerModule",
        canActivate: [RoleGuard],
      },
      {
        path: "forms",
        loadChildren: "./pages/forms/forms.module#FormsModule",
      },
      {
        path: "form-history",
        loadChildren:
          "./pages/form-history/form-history.module#FormHistoryModule",
      },
      {
        path: "supports",
        loadChildren: "./pages/supports/supports.module#SupportsModule",
      },
      {
        path: "meeting-minute",
        loadChildren:
          "./pages/meeting-minute/meeting-minute.module#MeetingMinuteModule",
      },
    
    
      {
        path: "",
        redirectTo: "index",
        pathMatch: "full",
      },
      {
        path: "leave_approval",
        loadChildren: "./pages/leave/leave-approval.module#LeaveApprovalModule",
      },
      {
        path: "issue_leave",
        loadChildren: "./pages/leave/leave-issue.module#LeaveIssueModule",
      },
      {
        path: "leave_setting",
        loadChildren: "./pages/leave/leave-setting.module#LeaveSettingModule",
      },
      {
        path: "leave_overview",
        loadChildren: "./pages/leave/leave-overview.module#LeaveOverviewModule",
      },
      {
        path: "request-management",
        loadChildren:
          "./pages/request-management/request-management.module#RequestManagementModule",
      },
      {
        path: "entity-management",
        loadChildren: "./pages/entity/entity.module#EntityModule",
      },
      {
        path: "assigned-projects",
        loadChildren:
          "./pages/assigned-projects/assigned-projects.module#AssignedProjectsModule",
      },
      {
        path: "commission-type",
        loadChildren:
          "./pages/commission/commission-type/commission-type.module#CommissionTypeModule",
      },
      {
        path: "commission-dashboard",
        loadChildren:
          "./pages/commission/commission-dashboard/commission-dashboard.module#CommissionDashboardModule",
      },
      {
        path: "commission-projects",
        loadChildren:
          "./pages/commission/commission-projects/commission-projects.module#CommissionProjectsModule",
      },
      {
        path: "request",
        loadChildren:
          "./pages/request/request.module#RequestModule",
      },
      {
        path: "wiki-notes",
        loadChildren:
          "./pages/wiki-notes/wiki-notes.module#WikiNotesModule",
      },
    ],
  },
  {
    path: "**",
    redirectTo: "index",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemeRoutingModule {}
