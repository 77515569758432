import { Injectable } from "@angular/core";
import { AppService } from "./app.service";


@Injectable()
export class LocationService extends AppService {


    getCurrentCountry(location) {
        this.flexHttp.init({
            rootURL: `https://nominatim.openstreetmap.org`
        })
        return this.flexHttp.get({
            url: `/reverse?lat=${location.latitude}&lon=${location.longitude}&format=jsonv2`,
            successMap: (response: any) => {
                this.initFlexHttp();
                return response;
            }
        })
    }
   
}