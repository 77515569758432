import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { ImageCropperModule } from "ngx-image-cropper";
import { TranslateModule } from "@ngx-translate/core";
import { NgxUploaderModule } from 'ngx-uploader';

import { Select2Component } from "./select2/select2.component";
import { SelectListComponent } from "./select-list/select-list.component";
import { TextBoxComponent } from "./text-box/text-box.component";
import { AutoCompleteComponent } from "./autocomplete/autocomplete.component";
import { SelectPickerComponent } from "./select-picker/select-picker.component";
import { ImageCropComponent } from "./image-crop/image-crop.component";
import { ZoomComponent } from "./zoom/zoom.component";
import { TypeaheadComponent } from "./typeahead/typeahead.component";
import { RangePickerComponent } from "./range-picker/range-picker.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { Angular2SignaturepadModule } from "angular2-signaturepad";
import { ErrorBoxComponent } from "./error-box/errorbox.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { IosSwitchComponent } from "./ios-switch/ios-switch.component";

import { FilterPipe } from "app/_pipes/filter.pipe";
import { TypePipe } from "app/_pipes/type.pipe";
import { NewSelect2Component } from "./new-select2/new-select2.component";
import { DatatableSearchbarComponent } from "./datatable-searchbar/datatable-searchbar.component";
import { StatusBadgeComponent } from "./status-badge/status-badge.component";
import { DatePickerDashboardComponent } from "./date-picker-dashboard/date-picker-dashboard.component";
import { AttachmentComponent } from "./attachment/attachment.component";

@NgModule({
  declarations: [
    NewSelect2Component,
    Select2Component,
    SelectListComponent,
    TextBoxComponent,
    SelectPickerComponent,
    AutoCompleteComponent,
    ImageCropComponent,
    ZoomComponent,
    TypeaheadComponent,
    RangePickerComponent,
    FilterPipe,
    TypePipe,
    DatePickerComponent,
    DatePickerDashboardComponent,
    ErrorBoxComponent,
    ProgressBarComponent,
    IosSwitchComponent,
    DatatableSearchbarComponent,
    StatusBadgeComponent,
    AttachmentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    TranslateModule,
    NgxUploaderModule
  ],
  exports: [
    NewSelect2Component,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Select2Component,
    TranslateModule,
    SelectListComponent,
    TextBoxComponent,
    SelectPickerComponent,
    AutoCompleteComponent,
    ZXingScannerModule,
    ImageCropComponent,
    ZoomComponent,
    TypeaheadComponent,
    RangePickerComponent,
    DatePickerComponent,
    DatePickerDashboardComponent,
    FilterPipe,
    TypePipe,
    Angular2SignaturepadModule,
    ErrorBoxComponent,
    ProgressBarComponent,
    IosSwitchComponent,
    DatatableSearchbarComponent,
    StatusBadgeComponent,
    AttachmentComponent
  ],
})
export class SharedModule {}
