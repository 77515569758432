import { Injectable } from "@angular/core";
import { AppService } from "./app.service";

@Injectable()
export class ServicesService extends AppService {
  //  ######   ######## ##    ## ######## ########     ###    ##
  // ##    ##  ##       ###   ## ##       ##     ##   ## ##   ##
  // ##        ##       ####  ## ##       ##     ##  ##   ##  ##
  // ##   #### ######   ## ## ## ######   ########  ##     ## ##
  // ##    ##  ##       ##  #### ##       ##   ##   ######### ##
  // ##    ##  ##       ##   ### ##       ##    ##  ##     ## ##
  //  ######   ######## ##    ## ######## ##     ## ##     ## ########

  uploadImage() {
    return `/general/upload`;
  }

  oldLogin() {
    return `/general/login/old`;
  }

  login() {
    return `/general/login`;
  }

  manualLogout() {
    return `/general/logout`;
  }

  changePassword() {
    return `/general/change_password`;
  }

  getPublicHoliday(year) {
    return `/leave/pubhols/${year}`;
  }

  // ########  ########   #######  ########  ########   #######  ##      ## ##    ##
  // ##     ## ##     ## ##     ## ##     ## ##     ## ##     ## ##  ##  ## ###   ##
  // ##     ## ##     ## ##     ## ##     ## ##     ## ##     ## ##  ##  ## ####  ##
  // ##     ## ########  ##     ## ########  ##     ## ##     ## ##  ##  ## ## ## ##
  // ##     ## ##   ##   ##     ## ##        ##     ## ##     ## ##  ##  ## ##  ####
  // ##     ## ##    ##  ##     ## ##        ##     ## ##     ## ##  ##  ## ##   ###
  // ########  ##     ##  #######  ##        ########   #######   ###  ###  ##    ##

  getEmpRoles() {
    return `/general/dropdown/all-employee-roles`;
  }

  inventoryStatus() {
    return `/general/dropdown/inventory-status`;
  }

  getFacilityDatatableStatus() {
    return "/general/dropdown/resource-status";
  }

  getFacilityDatatableCategory() {
    return "/general/dropdown/facility-category";
  }

  getFacilityDatatableEntity() {
    return "/general/dropdown/entity";
  }

  getFacilityDatatableCapacity() {
    return "/general/dropdown/facility-capacity";
  }

  getBuildInAssetName() {
    return "/general/dropdown/asset-name";
  }

  getBuildInAssetSn() {
    return `/general/dropdown/serial-number`;
  }

  getAssetCategory() {
    return `/general/dropdown/asset-category`;
  }

  getArchivedAssetCategory() {
    return `/general/dropdown/asset-archived/asset-category`;
  }

  getAllCompanyName() {
    return `/general/dropdown/customer-name`;
  }

  getAllAreas() {
    return `/general/dropdown/entity-area`;
  }

  inventoryCondition() {
    return `/general/dropdown/inventory-condition`;
  }

  getEmployeeByUserDept() {
    return `/general/dropdown/employee-user-department`;
  }

  //  ########   #######   #######  ##    ## #### ##    ##  ######
  //  ##     ## ##     ## ##     ## ##   ##   ##  ###   ## ##    ##
  //  ##     ## ##     ## ##     ## ##  ##    ##  ####  ## ##
  //  ########  ##     ## ##     ## #####     ##  ## ## ## ##   ####
  //  ##     ## ##     ## ##     ## ##  ##    ##  ##  #### ##    ##
  //  ##     ## ##     ## ##     ## ##   ##   ##  ##   ### ##    ##
  //  ########   #######   #######  ##    ## #### ##    ##  ######

  quickBookingCheck() {
    return `/booking/quick-booking`;
  }

  facilityBookingDatatable() {
    return `/booking/facility/datatable`;
  }

  facilityBookingCalendar() {
    return `/booking/facility/calendar`;
  }

  getEntityByBookableResources(type) {
    return `/booking/my-entities/${type}`;
  }

  getAllBookableFacility() {
    return `/booking/bookable-facility`;
  }

  getFacilityByEntity(id) {
    return `/booking/entity-facilities/${id}`;
  }

  getAssetByEntity(id) {
    return `/booking/entity-assets/${id}`;
  }

  getAssetAvailableSerialNum() {
    return `/booking/available-serial-number`;
  }

  bookFacility() {
    return `/booking/facility`;
  }

  genericFacilityBookingWithId(id) {
    return `/booking/facility/${id}`;
  }

  bookAsset() {
    return `/booking/asset`;
  }

  genericAssetBookingWithId(id) {
    return `/booking/asset/${id}`;
  }

  getUpcomingMyBookingDatatable() {
    return `/booking/dt`;
  }

  getHistoricalMyBookingDatatable() {
    return `/booking/dt-historical`;
  }

  /*
    ###     ###  ########  ########  ########  ####  ###     ##   #######
    ####   ####  ##        ##           ##      ##   ####    ##  ##     ##
    ## ## ## ##  ##        ##           ##      ##   ## ##   ##  ##
    ##  ###  ##  #######   #######      ##      ##   ##  ##  ##  ##    ####
    ##       ##  ##        ##           ##      ##   ##   ## ##  ##     ##
    ##       ##  ##        ##           ##      ##   ##    ####  ##     ##
    ##       ##  ########  ########     ##     ####  ##     ###   #######
    */

  genericMeeting() {
    return `/meeting`;
  }

  meetingDatatable() {
    return `/meeting/datatable`;
  }

  genericMeetingWithId(id) {
    return `/meeting/` + id;
  }

  deleteAttendance(id) {
    return `/meeting/attendance/${id}`;
  }

  // for hard code minute taker
  uploadMinuteTaker() {
    return `/meeting/minute-taker/hardcode-minute-taker`;
  }

  getMinuteTakerList() {
    return `/meeting/minute-taker/department_date`;
  }

  // ########  ########   #######        ## ########  ######  ########
  // ##     ## ##     ## ##     ##       ## ##       ##    ##    ##
  // ##     ## ##     ## ##     ##       ## ##       ##          ##
  // ########  ########  ##     ##       ## ######   ##          ##
  // ##        ##   ##   ##     ## ##    ## ##       ##          ##
  // ##        ##    ##  ##     ## ##    ## ##       ##    ##    ##
  // ##        ##     ##  #######   ######  ########  ######     ##

  getAllProjects() {
    return `/project`;
  }

  genericProject(id) {
    return `/project/${id}`;
  }

  syncProject() {
    return `/project/sync`;
  }

  getAllUserRoles() {
    return `/project/roles`;
  }

  projectDatatable() {
    return `/project/datatable`;
  }

  userProjectDatatable() {
    return `/project/employee/datatable`;
  }

  archiveProject(id) {
    return `/project/toggle/${id}`;
  }

  genericEmpProj(id) {
    return `/project/${id}/employee`;
  }

  getExcel() {
    return `/project/excel`;
  }

  updateCustomerWarranty() {
    return `/project/warranty/extend`;
  }

  exportAssignedUsers(id: number) {
    return `/project/users/excel/${id}`;
  }

  // ########    ###     ######  #### ##       #### ######## ##    ##
  // ##         ## ##   ##    ##  ##  ##        ##     ##     ##  ##
  // ##        ##   ##  ##        ##  ##        ##     ##      ####
  // ######   ##     ## ##        ##  ##        ##     ##       ##
  // ##       ######### ##        ##  ##        ##     ##       ##
  // ##       ##     ## ##    ##  ##  ##        ##     ##       ##
  // ##       ##     ##  ######  #### ######## ####    ##       ##

  genericFacility() {
    return `/facility`;
  }

  genericFacilityWithId(id) {
    return `/facility/${id}`;
  }

  getFacilityMgtDatatable() {
    return "/facility/datatable";
  }

  getFacilityHistoryDatatable() {
    return "/facility/datatable-bookings";
  }

  //   ###     ######   ######  ######## ########
  //  ## ##   ##    ## ##    ## ##          ##
  // ##   ##  ##       ##       ##          ##
  //##     ##  ######   ######  ######      ##
  //#########       ##       ## ##          ##
  //##     ## ##    ## ##    ## ##          ##
  //##     ##  ######   ######  ########    ##

  genericAsset() {
    return `/asset`;
  }

  genericAssetWithId(id) {
    return `/asset/${id}`;
  }

  assetDatatable() {
    return `/asset/datatable`;
  }

  exportAsset() {
    return `/asset-assign/datatable/excel`;
  }

  currentAssignmentDatatable() {
    return `/asset-assign/datatable`;
  }

  inactiveAssignmentDatatable() {
    return `/asset-archive/datatable`;
  }

  archiveAssetHistoryDatatable() {
    return `/asset-archive/datatable/history`;
  }

  assetHistoryDatatable() {
    return `/asset/datatable/history`;
  }

  genericAssignment() {
    return `/asset-assign`;
  }

  updateAssignment(id) {
    return `/asset-assign/${id}`;
  }

  // ########  ########  #######  ##     ## ########  ######  ########    ##     ##    ###    ##    ##    ###     ######   ######## ##     ## ######## ##    ## ########
  // ##     ## ##       ##     ## ##     ## ##       ##    ##    ##       ###   ###   ## ##   ###   ##   ## ##   ##    ##  ##       ###   ### ##       ###   ##    ##
  // ##     ## ##       ##     ## ##     ## ##       ##          ##       #### ####  ##   ##  ####  ##  ##   ##  ##        ##       #### #### ##       ####  ##    ##
  // ########  ######   ##     ## ##     ## ######    ######     ##       ## ### ## ##     ## ## ## ## ##     ## ##   #### ######   ## ### ## ######   ## ## ##    ##
  // ##   ##   ##       ##  ## ## ##     ## ##             ##    ##       ##     ## ######### ##  #### ######### ##    ##  ##       ##     ## ##       ##  ####    ##
  // ##    ##  ##       ##    ##  ##     ## ##       ##    ##    ##       ##     ## ##     ## ##   ### ##     ## ##    ##  ##       ##     ## ##       ##   ###    ##
  // ##     ## ########  ##### ##  #######  ########  ######     ##       ##     ## ##     ## ##    ## ##     ##  ######   ######## ##     ## ######## ##    ##    ##
  pendingBookingsDatatable() {
    return `/booking_request/pending`;
  }

  confirmedBookingsDatatable() {
    return `/booking_request/confirmed`;
  }

  archivedBookingsDatatable() {
    return `/booking_request/archived`;
  }

  getOneRequest(id) {
    return `/booking_request/get_request/${id}`;
  }

  approveRequest() {
    return `/booking_request/approve_request`;
  }

  rejectRequest() {
    return `/booking_request/reject_request`;
  }

  // ######## ##    ## ######## #### ######## ##    ##
  // ##       ###   ##    ##     ##     ##     ##  ##
  // ##       ####  ##    ##     ##     ##      ####
  // ######   ## ## ##    ##     ##     ##       ##
  // ##       ##  ####    ##     ##     ##       ##
  // ##       ##   ###    ##     ##     ##       ##
  // ######## ##    ##    ##    ####    ##       ##

  genericEntity() {
    return `/management/entity`;
  }

  genericEntityWithId(id) {
    return `/management/entity/${id}`;
  }

  getEntityDatatable() {
    return `/management/entity/datatable`;
  }

  //    ###    ##    ## ##    ##  #######  ##     ## ##    ##  ######  ######## ##     ## ######## ##    ## ########
  //   ## ##   ###   ## ###   ## ##     ## ##     ## ###   ## ##    ## ##       ###   ### ##       ###   ##    ##
  //  ##   ##  ####  ## ####  ## ##     ## ##     ## ####  ## ##       ##       #### #### ##       ####  ##    ##
  // ##     ## ## ## ## ## ## ## ##     ## ##     ## ## ## ## ##       ######   ## ### ## ######   ## ## ##    ##
  // ######### ##  #### ##  #### ##     ## ##     ## ##  #### ##       ##       ##     ## ##       ##  ####    ##
  // ##     ## ##   ### ##   ### ##     ## ##     ## ##   ### ##    ## ##       ##     ## ##       ##   ###    ##
  // ##     ## ##    ## ##    ##  #######   #######  ##    ##  ######  ######## ##     ## ######## ##    ##    ##

  genericAnnouncement() {
    return `/general/announcement`;
  }

  announcementDatatable() {
    return `/general/announcement/datatable`;
  }

  genericAnnouncementWithId(id) {
    return `/general/announcement/${id}`;
  }

  // ######## ##     ## ########  ##        #######  ##    ## ######## ########
  // ##       ###   ### ##     ## ##       ##     ##  ##  ##  ##       ##
  // ##       #### #### ##     ## ##       ##     ##   ####   ##       ##
  // ######   ## ### ## ########  ##       ##     ##    ##    ######   ######
  // ##       ##     ## ##        ##       ##     ##    ##    ##       ##
  // ##       ##     ## ##        ##       ##     ##    ##    ##       ##
  // ######## ##     ## ##        ########  #######     ##    ######## ########
  genericEmployee() {
    return `/management/employee`;
  }

  genericEmployeeWithId(id) {
    return `/management/employee/${id}`;
  }

  employeeDatatable() {
    return `/management/employee/datatable`;
  }

  syncEmployee() {
    return `/management/employee/sync`;
  }

  archiveEmployee(id) {
    return `/management/employee/toggle-meeting/${id}`;
  }

  updateEmpProfile() {
    return `/management/employee/profile`;
  }

  getEmployeeByDept(id) {
    return `/management/employee/department/${id}`;
  }

  exportEmployee() {
    return `/management/employee/list/export`;
  }

  // #### ##    ## ##     ## ######## ##    ## ########  #######  ########  ##    ##
  //  ##  ###   ## ##     ## ##       ###   ##    ##    ##     ## ##     ##  ##  ##
  //  ##  ####  ## ##     ## ##       ####  ##    ##    ##     ## ##     ##   ####
  //  ##  ## ## ## ##     ## ######   ## ## ##    ##    ##     ## ########     ##
  //  ##  ##  ####  ##   ##  ##       ##  ####    ##    ##     ## ##   ##      ##
  //  ##  ##   ###   ## ##   ##       ##   ###    ##    ##     ## ##    ##     ##
  // #### ##    ##    ###    ######## ##    ##    ##     #######  ##     ##    ##

  genericInventory() {
    return `/inventory/item`;
  }

  genericInventoryById(id) {
    return `/inventory/item/${id}`;
  }

  inventorySummaryDetails() {
    return `/inventory/item/detail`;
  }

  inventoryDatatable() {
    return `/inventory/item/datatable`;
  }

  inventoryArchiveDatatable() {
    return `/inventory/item/datatable/archive`;
  }

  inventoryDatatableDate() {
    return `/inventory/item/datatable/history`;
  }

  inventorySummaryDatatable() {
    return `/inventory/item/datatable/summary`;
  }

  getInventoryBySid(sid) {
    return `/inventory/item/serial-number/${sid}`;
  }

  getInventoryHistoryId(id) {
    return `/inventory/item/log/${id}`;
  }

  genericInventoryType() {
    return `/inventory/type`;
  }

  genericInventoryTypeWithId(id) {
    return `/inventory/type/${id}`;
  }

  updateSortingId() {
    return `/inventory/type/sort`;
  }

  genericInventoryModel() {
    return `/inventory/model`;
  }

  genericInventoryModelWithId(id) {
    return `/inventory/model/${id}`;
  }

  genericTag() {
    return `/inventory/tag`;
  }

  genericTagById(id) {
    return `/inventory/tag/${id}`;
  }

  getTagsDatatable() {
    return `/inventory/tag/datatable`;
  }

  //    ###    ######## ######## ######## ##    ## ########     ###    ##    ##  ######  ########
  //   ## ##      ##       ##    ##       ###   ## ##     ##   ## ##   ###   ## ##    ## ##
  //  ##   ##     ##       ##    ##       ####  ## ##     ##  ##   ##  ####  ## ##       ##
  // ##     ##    ##       ##    ######   ## ## ## ##     ## ##     ## ## ## ## ##       ######
  // #########    ##       ##    ##       ##  #### ##     ## ######### ##  #### ##       ##
  // ##     ##    ##       ##    ##       ##   ### ##     ## ##     ## ##   ### ##    ## ##
  // ##     ##    ##       ##    ######## ##    ## ########  ##     ## ##    ##  ######  ########

  genericAttendance() {
    return `/attendance`;
  }

  getAttendanceTable() {
    return `/attendance/datatable`;
  }

  getOneAttendance(id) {
    return `/attendance/${id}`;
  }

  downloadAttendanceExcel() {
    return `/attendance/download`;
  }

  // ##     ## #### ##       ########  ######  ########  #######  ##    ## ########
  // ###   ###  ##  ##       ##       ##    ##    ##    ##     ## ###   ## ##
  // #### ####  ##  ##       ##       ##          ##    ##     ## ####  ## ##
  // ## ### ##  ##  ##       ######    ######     ##    ##     ## ## ## ## ######
  // ##     ##  ##  ##       ##             ##    ##    ##     ## ##  #### ##
  // ##     ##  ##  ##       ##       ##    ##    ##    ##     ## ##   ### ##
  // ##     ## #### ######## ########  ######     ##     #######  ##    ## ########

  // Milestone is renamed to journal

  getOneMilestone(id) {
    return `/milestone/${id}`;
  }

  submitDraftJournal() {
    return `/milestone/draft`;
  }

  milestoneDatatable() {
    return `/milestone/datatable`;
  }

  genericMilestoneWithId(id) {
    return `/milestone/draft/${id}`;
  }

  submitMilestone() {
    return `/milestone/draft/submit`;
  }

  getMilestoneByDate(date) {
    return `/milestone/datetime/${date}`;
  }

  getLastestMilestone(id) {
    return `/milestone/department/${id}`;
  }

  exportMilestoneExcel() {
    return `/milestone/hours/excel`;
  }

  // ########  #######  ########  ########  ######     ###     ######  ########
  // ##       ##     ## ##     ## ##       ##    ##   ## ##   ##    ##    ##
  // ##       ##     ## ##     ## ##       ##        ##   ##  ##          ##
  // ######   ##     ## ########  ######   ##       ##     ##  ######     ##
  // ##       ##     ## ##   ##   ##       ##       #########       ##    ##
  // ##       ##     ## ##    ##  ##       ##    ## ##     ## ##    ##    ##
  // ##        #######  ##     ## ########  ######  ##     ##  ######     ##
  getAllForecast() {
    return `/forecast/all`;
  }

  addOneForecast() {
    return `/forecast`;
  }

  updateOneForecast(id) {
    return `/forecast/update/${id}`;
  }

  deleteOneForecast() {
    return `/forecast/delete`;
  }

  getForecastDatatable() {
    return `/forecast/datatable`;
  }

  // ######## ##     ## ########  ######## ##    ##  ######  ########  ######
  // ##        ##   ##  ##     ## ##       ###   ## ##    ## ##       ##    ##
  // ##         ## ##   ##     ## ##       ####  ## ##       ##       ##
  // ######      ###    ########  ######   ## ## ##  ######  ######    ######
  // ##         ## ##   ##        ##       ##  ####       ## ##             ##
  // ##        ##   ##  ##        ##       ##   ### ##    ## ##       ##    ##
  // ######## ##     ## ##        ######## ##    ##  ######  ########  ######

  expenseDatatable() {
    return `/general/expense/datatable`;
  }

  genericExpense() {
    return `/general/expense`;
  }

  genericExpenseWithId(id) {
    return `/general/expense/${id}`;
  }

  updateExpense(id) {
    return `/general/expense/status/${id}`;
  }

  //##       ########    ###    ##     ## ########
  //##       ##         ## ##   ##     ## ##
  //##       ##        ##   ##  ##     ## ##
  //##       ######   ##     ## ##     ## ######
  //##       ##       #########  ##   ##  ##
  //##       ##       ##     ##   ## ##   ##
  //######## ######## ##     ##    ###    ########

  leaveAppDatatableAdmin() {
    return `/leave/app/admin/datatable`;
  }

  leaveAppDatatableUser() {
    return `/leave/app/datatable`;
  }

  issueLeave() {
    return `/leave/quota`;
  }

  retrieveDetails(year, leave_type, name) {
    return `/leave/quota/${year}/${leave_type}/${name}`;
  }

  retrieveUser(year, leave_type) {
    return `/leave/quota/${year}/${leave_type}`;
  }

  countQuota() {
    return `/leave/app/count`;
  }

  nonBipoLeaveApp() {
    return `/leave/app`;
  }

  genericNonBipoLeave(id) {
    return `/leave/app/id/${id}`;
  }

  bipoLeaveApp() {
    return `/leave/app/bipo`;
  }

  bipoLeaveUpdate(id) {
    return `/leave/app/bipo/id/${id}`;
  }

  getLeaveCalendar() {
    return `/leave/app/cal`;
  }

  approveLeave(id) {
    return `/leave/app/approve/${id}`;
  }

  rejectLeave(id) {
    return `/leave/app/reject/${id}`;
  }

  submitWithdrawal(id) {
    return `/leave/withdraw/submit/${id}`;
  }

  withdraw(id) {
    return `/leave/withdraw/admin/${id}`;
  }

  rejectWithdrawal(id) {
    return `/leave/withdraw/reject/${id}`;
  }

  getNonBipoTypes() {
    return `/leave/type/nonbipo`;
  }

  getBipoTypes() {
    return `/leave/type/bipo`;
  }

  addLeaveType() {
    return `/leave/type`;
  }

  deleteLeaveType(name) {
    return `/leave/type/${name}`;
  }

  getOverallLeaveStatus(year) {
    return `/leave/status/year/${year}`;
  }

  getAdminLeaveStatus() {
    return `/leave/status/datatable`;
  }

  getRecordLeaveStatus() {
    return `/leave/status/type`;
  }

  getOneLeaveData() {
    return `/leave/status/one`;
  }

  // ########     ###     ######  ##     ## ########   #######     ###    ########  ########
  // ##     ##   ## ##   ##    ## ##     ## ##     ## ##     ##   ## ##   ##     ## ##     ##
  // ##     ##  ##   ##  ##       ##     ## ##     ## ##     ##  ##   ##  ##     ## ##     ##
  // ##     ## ##     ##  ######  ######### ########  ##     ## ##     ## ########  ##     ##
  // ##     ## #########       ## ##     ## ##     ## ##     ## ######### ##   ##   ##     ##
  // ##     ## ##     ## ##    ## ##     ## ##     ## ##     ## ##     ## ##    ##  ##     ##
  // ########  ##     ##  ######  ##     ## ########   #######  ##     ## ##     ## ########

  getRecentActivites() {
    return `/general/dashboard/activities`;
  }

  getDashboardEmployee() {
    return `/general/dashboard/employee`;
  }

  getRecentAsset() {
    return `/general/dashboard/asset`;
  }

  getLatestService() {
    return `/general/dashboard/customer/recent`;
  }

  getLatestServiceLog() {
    return `/general/dashboard/service_log/recent`;
  }

  getMostService() {
    return `/general/dashboard/customer/most`;
  }

  getPendingCases() {
    return `/general/dashboard/customer/pending`;
  }

  getHistory(type) {
    return `/general/dashboard/service/${type}`;
  }

  getLogHistory(type) {
    return `/general/dashboard/service_log/${type}`;
  }

  getStaffWorkingHourCOmparison() {
    return `/general/dashboard/chart/service`;
  }

  //  ######  ##     ##  ######  ########  #######  ##     ## ######## ########
  // ##    ## ##     ## ##    ##    ##    ##     ## ###   ### ##       ##     ##
  // ##       ##     ## ##          ##    ##     ## #### #### ##       ##     ##
  // ##       ##     ##  ######     ##    ##     ## ## ### ## ######   ########
  // ##       ##     ##       ##    ##    ##     ## ##     ## ##       ##   ##
  // ##    ## ##     ## ##    ##    ##    ##     ## ##     ## ##       ##    ##
  //  ######   #######   ######     ##     #######  ##     ## ######## ##     ##

  getCustomerTable() {
    return `/management/customer/datatable`;
  }

  genericCustomer() {
    return `/management/customer`;
  }

  genericCustomerWithId(id) {
    return `/management/customer/${id}`;
  }

  toggleArchiveCustomer(id) {
    return `/management/customer/toggle-meeting/${id}`;
  }

  getHistoryUpdateLog() {
    return `/management/customer/datatable/update`;
  }

  // ########  #######  ########  ##     ##
  // ##       ##     ## ##     ## ###   ###
  // ##       ##     ## ##     ## #### ####
  // ######   ##     ## ########  ## ### ##
  // ##       ##     ## ##   ##   ##     ##
  // ##       ##     ## ##    ##  ##     ##
  // ##        #######  ##     ## ##     ##

  // For Customer
  getHistoryServiceForm() {
    return `/form/service/history/company`;
  }

  getHistoryDeployementForm() {
    return `/form/deployment/retrieve_company`;
  }

  getHistoryDeinstallationForm() {
    return `/form/deinstallation/retrieve_company`;
  }

  getHistoryReplacementForm() {
    return `/form/replacement/retrieve_company`;
  }

  getHistoryEvidenceForm() {
    return `/form/service_evidence/retrieve_company`;
  }

  // Service Form
  getSubmittedFormTable() {
    return `/form/service/history`;
  }

  genericServiceForm() {
    return `/form/service/submit`;
  }

  getOneServiceForm() {
    return `/form/service/single`;
  }

  getSingleTrainingForm() {
    return `/form/training/retrieve_specific`;
  }

  deleteServiceForm(id) {
    return `/form/service/${id}`;
  }

  // Deployment Form
  getDeploymentFormTable() {
    return `/form/deployment/retrieve_all`;
  }

  genericDeploymentForm() {
    return `/form/deployment/submit`;
  }

  getOneDeploymentForm() {
    return `/form/deployment/retrieve_specific`;
  }

  // Deinstallation Form
  getDeinstallTable() {
    return `/form/deinstallation/retrieve_all`;
  }

  genericDeinstallForm() {
    return `/form/deinstallation/submit`;
  }

  getOneDeinstallForm() {
    return `/form/deinstallation/retrieve_specific`;
  }

  // Training Form
  getTrainingTypesList() {
    return `/form/training/types`;
  }

  submitTrainingForm() {
    return `/form/training/submit`;
  }

  getTrainingFormTable() {
    return `/form/training/retrieve_all`;
  }

  // Replacement Form
  getReplaceTable() {
    return `/form/replacement/retrieve_all`;
  }

  generitReplaceForm() {
    return `/form/replacement/submit`;
  }

  getOneReplaceForm() {
    return `/form/replacement/retrieve_specific`;
  }

  // Service Log
  submitServiceEvidence() {
    return `/form/service_evidence/submit`;
  }

  updateServiceEvidence() {
    return `/form/service_evidence/update`;
  }

  getSingleEvidence(id) {
    return `/form/service_evidence/retrieve_specific/${id}`;
  }

  evidenceDatatable() {
    return `/form/service_evidence/get_all`;
  }

  getAllServiceTypes() {
    return `/form/service_evidence/get_types`;
  }

  deleteSingleEvidence(id) {
    return `/form/service_evidence/delete/${id}`;
  }

  // Form History
  getAllFormHistory() {
    return `/form/history/datatable`;
  }

  downloadExcel() {
    return `/form/history/excel`;
  }

  // #######  ##    ## ######## ######## ##       ######## ##     ##
  // ##     ## ###   ## ##       ##       ##       ##        ##   ##
  // ##     ## ####  ## ##       ##       ##       ##         ## ##
  // ##     ## ## ## ## ######   ######   ##       ######      ###
  // ##     ## ##  #### ##       ##       ##       ##         ## ##
  // ##     ## ##   ### ##       ##       ##       ##        ##   ##
  //  #######  ##    ## ######## ##       ######## ######## ##     ##

  getAllHolidays() {
    return `/oneflex/holiday`;
  }

  createOneHoliday() {
    return `/oneflex/holiday/create`;
  }

  updateOneHoliday() {
    return `/oneflex/holiday/update`;
  }

  deleteOneHoliday() {
    return `/oneflex/holiday/date`;
  }

  genericOneflexUser() {
    return `/oneflex/user`;
  }

  genericOneflexAccWithId(id) {
    return `/oneflex/user/${id}`;
  }

  enableOneAccount() {
    return `/oneflex/user/enable`;
  }

  getAllAccounts() {
    return `/oneflex/user/allUsers`;
  }

  //######## ########     ###    #### ##    ## #### ##    ##  ######
  //  ##    ##     ##   ## ##    ##  ###   ##  ##  ###   ## ##    ##
  //  ##    ##     ##  ##   ##   ##  ####  ##  ##  ####  ## ##
  //  ##    ########  ##     ##  ##  ## ## ##  ##  ## ## ## ##   ####
  //  ##    ##   ##   #########  ##  ##  ####  ##  ##  #### ##    ##
  //  ##    ##    ##  ##     ##  ##  ##   ###  ##  ##   ### ##    ##
  //  ##    ##     ## ##     ## #### ##    ## #### ##    ##  ######

  genericTraining() {
    return `/training`;
  }

  genericTrainingeWithId(id) {
    return `/training/${id}`;
  }

  userTrainingDatatable() {
    return `/training/datatable/user`;
  }

  registerTraining() {
    return `/training/register`;
  }

  getAllTrainingSchedules() {
    return `/training/trainee/schedule`;
  }

  genericAdminTraining() {
    return `/training-admin`;
  }

  genericAdminTrainingeWithId(id) {
    return `/training-admin/${id}`;
  }

  adminTrainingDatatable() {
    return `/training-admin/datatable`;
  }

  toggleAdminTraining(id) {
    return `/training-admin/toggle/${id}`;
  }

  getTrainingUsers(id) {
    return `/training-admin/trainee/${id}`;
  }

  updateUserInTraining() {
    return `/training-admin/trainee/update`;
  }

  // ##     ##    ###    ##    ##    ###     ######   ######## ##     ## ######## ##    ## ########
  // ###   ###   ## ##   ###   ##   ## ##   ##    ##  ##       ###   ### ##       ###   ##    ##
  // #### ####  ##   ##  ####  ##  ##   ##  ##        ##       #### #### ##       ####  ##    ##
  // ## ### ## ##     ## ## ## ## ##     ## ##   #### ######   ## ### ## ######   ## ## ##    ##
  // ##     ## ######### ##  #### ######### ##    ##  ##       ##     ## ##       ##  ####    ##
  // ##     ## ##     ## ##   ### ##     ## ##    ##  ##       ##     ## ##       ##   ###    ##
  // ##     ## ##     ## ##    ## ##     ##  ######   ######## ##     ## ######## ##    ##    ##
  getdepartment() {
    return `/management/department`;
  }

  getDeptIfLeader() {
    return `/management/department/leader`;
  }

  getSubDept() {
    return `/management/department/sub`;
  }

  searchCompanyName() {
    return `${this.SERVER_ADDRESS}/management/customer`;
  }

  searchCompanyAddress() {
    return `${this.SERVER_ADDRESS}/management/customer`;
  }

  searchProduct() {
    return `/product/search`;
  }

  searchEmployee() {
    return `/employee/sales/search`;
  }

  //  ######   #######  ##     ## ##     ## ####  ######   ######  ####  #######  ##    ##
  // ##    ## ##     ## ###   ### ###   ###  ##  ##    ## ##    ##  ##  ##     ## ###   ##
  // ##       ##     ## #### #### #### ####  ##  ##       ##        ##  ##     ## ####  ##
  // ##       ##     ## ## ### ## ## ### ##  ##   ######   ######   ##  ##     ## ## ## ##
  // ##       ##     ## ##     ## ##     ##  ##        ##       ##  ##  ##     ## ##  ####
  // ##    ## ##     ## ##     ## ##     ##  ##  ##    ## ##    ##  ##  ##     ## ##   ###
  //  ######   #######  ##     ## ##     ## ####  ######   ######  ####  #######  ##    ##
  // Commission Type
  commissionTypeDatatable() {
    return `/commission/type/datatable`;
  }

  genericCommissionType() {
    return `/commission/type`;
  }

  commissionTypeWithId(id) {
    return `/commission/type/${id}`;
  }

  commissionTypeWithCode(code) {
    return `/commission/type/${code}`;
  }

  getCommissionTypeWithCode(code) {
    return `/commission/type/code?code=${code}`;
  }

  // Commission Project
  genericCommissionProject() {
    return `/commission/project`;
  }

  commissionProjectDatatable() {
    return `/commission/project/datatable`;
  }

  getProjectDetails() {
    return `/commission/project/details`;
  }

  commissionPaymentHistoryTable() {
    // customer payment
    return `/commission/project/payment/history`;
  }

  getUsersInProject(id) {
    return `/project/employee/${id}`;
  }

  commissionPayoutHistoryTable() {
    // commission payment
    return `/commission/payout`;
  }

  getProjSowNo() {
    return `/commission/project/code`;
  }

  auditTrailTable() {
    return `/commission/audit_trail`;
  }

  // Commission Breakdown
  // for the modal
  commissionBreakdownByEmployeeTable() {
    return `/commission/employee/payout`;
  }

  // Commission Payout
  makeCommissionPayment() {
    return `/commission/payment`;
  }

  revertCommissionPayment() {
    return `/commission/payment/revert`;
  }

  genericPayout() {
    return `/commission/payout/milestone`;
  }

  // Commission Dashboard
  getCommissionDashboardData() {
    return `/commission/dashboard/user`;
  }

  getPayoutDetails() {
    return `/commission/dashboard/user/milestone`;
  }

  // ########  ########  #######  ##     ## ########  ######  ########
  // ##     ## ##       ##     ## ##     ## ##       ##    ##    ##
  // ##     ## ##       ##     ## ##     ## ##       ##          ##
  // ########  ######   ##     ## ##     ## ######    ######     ##
  // ##   ##   ##       ##  ## ## ##     ## ##             ##    ##
  // ##    ##  ##       ##    ##  ##     ## ##       ##    ##    ##
  // ##     ## ########  ##### ##  #######  ########  ######     ##

  requestDatatable() {
    return `/request/datatable`;
  }

  genericRequest(id?: number) {
    if (id) {
      return `/request/${id}`;
    }
    return `/request`;
  }

  onApproveRequest(id: number) {
    return `/request/approve/${id}`;
  }

  onRejectRequest(id: number) {
    return `/request/reject/${id}`;
  }

  onCompleteRequest(id: number) {
    return `/request/complete/${id}`;
  }

// ██╗    ██╗██╗██╗  ██╗██╗    ███╗   ██╗ ██████╗ ████████╗███████╗███████╗    
// ██║    ██║██║██║ ██╔╝██║    ████╗  ██║██╔═══██╗╚══██╔══╝██╔════╝██╔════╝    
// ██║ █╗ ██║██║█████╔╝ ██║    ██╔██╗ ██║██║   ██║   ██║   █████╗  ███████╗    
// ██║███╗██║██║██╔═██╗ ██║    ██║╚██╗██║██║   ██║   ██║   ██╔══╝  ╚════██║    
// ╚███╔███╔╝██║██║  ██╗██║    ██║ ╚████║╚██████╔╝   ██║   ███████╗███████║    
//  ╚══╝╚══╝ ╚═╝╚═╝  ╚═╝╚═╝    ╚═╝  ╚═══╝ ╚═════╝    ╚═╝   ╚══════╝╚══════╝    
                                                                            
wikiRepoDatatable() {
  return `/wiki-note/repository/datatable`;
}

getProjectList(){
  return `/wiki-note/repository/projectName`
}

createNewRepo(){
  return `/wiki-note/repository/create`;
}

deleteRepo(){
  return `/wiki-note/repository/delete`
}

saveEditRepo(){
  return `/wiki-note/repository/edit`
}

getWikiRepo(id?:number){
  if (id) {
    return `/wiki-note/repository/${id}`;
  }
  return `/wiki-note`;
}

wikiNotesDataTable(){
  return `/wiki-note/note/datatable`
}

getNotesCategory(){
  return `/wiki-note/note/category`
}

createNote(){
  return `/wiki-note/note/create`
}

getNote(id?:number){
  if(id){
  return `/wiki-note/note/${id}`
  }
  return `/wiki-note`;
}

editNote(){
  return `/wiki-note/note/edit`
}

deleteNote(){
  return `/wiki-note/note/delete`
}
}
