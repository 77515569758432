import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ThemeComponent } from "./theme/theme.component";
import { LayoutModule } from "./theme/layouts/layout.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ScriptLoaderService } from "@services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { AuthModule } from "./auth/auth.module";
import { ServicesModule } from "@services/_services.module";

import { ResetComponent } from "./reset/reset.component";

import { NgFlexHttpModule } from "@flexsolver/ng-flex-http";
import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { NgxImageCompressService } from "ngx-image-compress";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { CountUpModule } from "ngx-countup";
import { Angular2SignaturepadModule } from "angular2-signaturepad";
import { RoleGuard } from "./auth/_guards/role.guard";
import { MsalModule, MsalService } from "@azure/msal-angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [ThemeComponent, AppComponent, ResetComponent],
  imports: [
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ThemeRoutingModule,
    AuthModule,
    ServicesModule,
    NgFlexHttpModule,
    NgxMaterialTimepickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    Angular2SignaturepadModule,
    MsalModule,
    CountUpModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    ScriptLoaderService,
    NgxImageCompressService,
    RoleGuard,
    MsalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
