import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { Logger, CryptoUtils } from "msal";
import { environment } from "environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "./_services/alert.service";
import { ServicesService } from "@services/services.service";
import { AlertComponent } from "./_directives/alert.component";
import { MsalService, BroadcastService } from "@azure/msal-angular";

@Component({
  selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
  templateUrl: "./auth.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit {
  isLogout = 0;

  preLang;
  language;
  model: any = {};
  loading = false;
  isMobile = false;
  returnUrl: string;
  loginShowPass = false;
  default: string = "en";
  year = moment().format("YYYY");
  subscriptions: Subscription[] = [];

  resetEmail;
  validEmail = null;
  codeError = false;
  passwordError = false;

  env = environment;

  @ViewChild("alertSignin", { read: ViewContainerRef, static: false })
  alertSignin: ViewContainerRef;
  @ViewChild("alertSignup", { read: ViewContainerRef, static: false })
  alertSignup: ViewContainerRef;
  @ViewChild("alertForgotPass", { read: ViewContainerRef, static: false })
  alertForgotPass: ViewContainerRef;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _services: ServicesService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver,
    private translate: TranslateService,
    private authService: MsalService,
    private broadcastService: BroadcastService
  ) {
    this.isLogout = Number(localStorage.getItem(`isLogout`));
    localStorage.clear();
  }

  ngOnInit() {
    this.model.remember = true;
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams[""] || "/";

    this.getLanguage();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    }
    if (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      navigator.userAgent.match(/Mac/)
    ) {
      this.isMobile = true;
    }
    if (this.isMobile) {
      this.initAD();
    }
  }

  initAD() {
    // active directory msal
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    loginSuccessSubscription = this.broadcastService.subscribe(
      "msal:loginSuccess",
      async (response) => {
        if (this.isLogout && this.isMobile) {
          return;
        }
        await this.loginAndStoreToken(response);
      }
    );

    loginFailureSubscription = this.broadcastService.subscribe(
      "msal:loginFailure",
      (error) => {
        this.loadingFailed();
      }
    );

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        return;
      }
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    this.authService.setLogger(
      new Logger((logLevel, message, piiEnabled) => {}, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false,
      })
    );
  }

  async loginAndStoreToken(ad_response) {
    const account = this.authService.getAccount();
    let token = this._services.getToken("msal.idtoken");

    if (!token) {
      try {
        token = ad_response["idToken"]["rawIdToken"];
        if (token) {
          this._services.setToken(token, "msal.idtoken");
        }
      } catch (e) {}
    }

    if (account) {
      let body = { token: token, ad_response: ad_response };
      this._services.http(`post`, this._services.login(), body).subscribe(
        (response: any) => {
          let user = response.data;
          this._services.setCurrentUser(user);
          this._services.setToken(user.token);
          this._services.initFlexHttp();

          let is_room = user.is_room;

          let calendarUrl =
            this._route.snapshot.queryParams["calendar"] || "/calendar";

          if (is_room) {
            this._services.router.navigate([calendarUrl]);
          }
          this._router.navigate([this.returnUrl]);
        },
        (error) => {
          this.loading = false;
          this.showAlert("alertSignin");
          let msg =
            error.error.message || error.__zone_symbol__value.error.message;
          this._alertService.error(msg);
        }
      );
    } else {
      this.loadingFailed();
    }
  }

  loadingFailed() {
    this.loading = false;
    this.showAlert("alertSignin");
    this._alertService.error(`Microsoft login failed. Please try again.`);
  }

  checkUsername() {
    if (this.model.code && this.model.code !== "") {
      this.codeError = false;
    }
  }

  checkPassword() {
    if (this.model.password && this.model.password !== "") {
      this.passwordError = false;
    }
  }

  signin() {
    this.codeError = false;
    this.passwordError = false;

    if (!this.model.code && !this.model.password) {
      this.codeError = true;
      this.passwordError = true;
      this._services.showNotification(
        "Error",
        "Please enter username and password!",
        "danger"
      );
      return;
    }

    if (this.model.code === "" || !this.model.code) {
      this.codeError = true;
      this._services.showNotification(
        "Error",
        "Please enter username!",
        "danger"
      );

      return;
    }

    if (this.model.password === "" || !this.model.password) {
      this.passwordError = true;
      this._services.showNotification(
        "Error",
        "Please enter password!",
        "danger"
      );
      return;
    }

    let data = {
      code: this.model.code,
      password: this.model.password,
    };

    this._services
      .http(`post`, this._services.oldLogin(), data, false, false, false, false)
      .subscribe(
        (response: any) => {
          let token = response.data.token;
          let user = response.data;
          delete user.token;

          let firstTimeUser = user["isFirstTime"];
          let changePasswrodUrl =
            this._route.snapshot.queryParams["newusers"] || "/newusers";

          if (firstTimeUser) {
            this._services
              .swalNotification(
                null,
                "First Time User",
                "Change your password immediately",
                false
              )
              .then((result) => {
                this._services.router.navigate([changePasswrodUrl]);
              });
          }
          if (token) {
            this._services.setCurrentUser(user);
            this._services.setToken(token);
            this._services.initFlexHttp();
          }

          let is_room = user.is_room;

          let calendarUrl =
            this._route.snapshot.queryParams["calendar"] || "/calendar";

          if (is_room) {
            this._services.router.navigate([calendarUrl]);
          }
          this._router.navigate([this.returnUrl]);
        },
        (error) => {
          this.showAlert("alertSignin");
          this.loading = false;
          this._alertService.error(error.error.message);
        }
      );
  }

  signinMicrosoft() {
    this.loading = true;
    if (!this.isMobile) {
      this.initAD();
    }
    const isIE =
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1;
    var request = {
      scopes: ["user.read"],
      responseMode: "fragment",
      // extraQueryParameters: {domain_hint: 'organizations'}
    };
    if (isIE || this.isMobile) {
      this.authService.loginPopup(request);
      // this.authService.loginRedirect(request);
    } else {
      this.authService.loginPopup(request);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  getLanguage() {
    this.preLang = localStorage.getItem("language");

    if (this.preLang) {
      this.translate.use(this.preLang);
      this.language = this.preLang;
    } else {
      localStorage.setItem("language", this.default);
      this.translate.use(this.default);
      this.language = this.default;
    }
  }

  resetModal() {
    $(document.body).removeClass("modal-open");
    $(".modal-backdrop").remove();
    $("#forget_modal").hide();
    this.validEmail = null;
    this.resetEmail = "";
  }

  validateEmail() {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(String(this.resetEmail).toLowerCase())
      ? (this.validEmail = true)
      : (this.validEmail = false);
  }
}
