import { Injectable } from '@angular/core';
import { objectEach } from 'highcharts';


@Injectable() 

export class ExcelService {
    //to download the file
    downloadFile(data , filename='data'){
        let csvData = this.convertToCsv(data);

        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        //get the dom
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";

        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    //convert to csv
    convertToCsv(objArr){
        let arr = typeof objArr != 'object' ? JSON.parse(objArr) : objArr;
        let str = '';
        let row = '';
        let headers;

        for (let newArr of  Object.values(arr)){
            headers = Object.keys(newArr)
            break;
        }

        for (let index of headers){
            index = this.renameCamel(index)
            row +=  index + ','
        };

        row = row.slice(0 , -1);

    
        str += row + '\r\n';
         for (let i = 0; i < arr.length; i++) {
             let line = (i+1)+'';
             for (let index in headers) {
                let head = headers[index];
                 line += ',' + arr[i][head];
             }
             str += line + '\r\n';
         }
         return str;
    }

    renameCamel(str){

        let newText = str.replace(/([A-Z]+)/g, " $1")
        let finalStr = newText.substring(0, 1).toUpperCase() + newText.substring(1);
        
        return finalStr
    }
}