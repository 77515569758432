import { InjectionToken } from "@angular/core";
export const SECRET = "@flex-token@";
export const HASH = "@flex-abc@";
export const TOKE_NAME = "voffice-token";
export const USER_NAME = "voffice-user";
export const ENCRYPTION = false;

export const VERSION = "0.0.1";

export const ROLE = ["SUPER ADMIN", "ADMIN", "STAFF"];

export const SERVICETYPE = [
  "Basic Warranty",
  "Standard Warranty",
  "Extended Warranty",
  "Premium Warranty",
  "Non-Warranty",
  "Chargeable",
  "Other",
  "Out of Warranty",
  "Extension",
  "Cloud",
];

// Inventory
export const STATUS = ["ALL", "PENDING", "COMPLETED"];
export const CONDITION = ["ALL", "NEW", "USED", "DAMAGED", "DISCARDED"];

// Forms
export const ACTIONTYPE = [
  "Remote support service (Human error)",
  "Remote support service (non human error) ",
  "On-site service ",
  "Urgent on-site service",
  "Report bug",
];
export const TESTRESULT_DEINSTALL = ["Collected", "Missing", "Other"];
export const TESTRESULT_DEPLOY = ["OK", "Failed", "Other"];

// Expenses
export const EXPENSE_CATEGORY = [
  {
    type: "Food",
    remark: "",
  },
  {
    type: "Transport",
    remark: "",
  },
  {
    type: "Flight",
    remark: "",
  },
  {
    type: "Fuel",
    remark: "",
  },
  {
    type: "IT Related",
    remark: "",
  },
  {
    type: "Lodging",
    remark: "",
  },
  {
    type: "Other Expenses ",
    remark: "(Specify in description)",
  },
];

export const MONTHS = [
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

export const ENV = [
  {
    id: 1,
    name: "DEV",
  },
  {
    id: 2,
    name: "PROD",
  },
];

export declare const MSAL_GUARD_CONFIG: InjectionToken<string>;
