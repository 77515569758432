import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
} from "@angular/core";
import { ServicesService } from "@services/services.service";

declare let mLayout: any;
@Component({
  selector: "app-header-nav",
  templateUrl: "./header-nav.component.html",
  styleUrls: ["./header-nav.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
  language;
  userDetails;
  hideHeader = false;

  constructor(private _services: ServicesService) {}

  ngOnInit() {
    this.userDetails = this._services.getCurrentUser();
    this.hideHeader = !this._services.checkIsCalendarComponent();
  }

  ngAfterViewInit() {
    mLayout.initHeader();
  }

  mAsideToggle() {
    $("#m_aside_left").toggleClass("m-aside-left--on");
  }

  logout() {
    this._services.http(`get`, this._services.manualLogout()).subscribe(() => {
      this._services.logout();
    });
  }
}
