import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { AlertComponent } from './_directives/alert.component';
import { AuthGuard } from './_guards/auth.guard';
import { AlertService } from './_services/alert.service';
import { AuthenticationService } from "./_services/authentication.service";
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { BroadcastService, MsalService, MSAL_CONFIG } from '@azure/msal-angular';
import { Configuration } from 'msal';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

// export function loggerCallback(logLevel: LogLevel, message: string) {
//     console.log(message);
// }


// export function MSALInstanceFactory(): IPublicClientApplication {
//     return new PublicClientApplication({
//       auth: {
//         clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6',
//         redirectUri: '/',
//         postLogoutRedirectUri: '/'
//       },
//       cache: {
//         cacheLocation: BrowserCacheLocation.LocalStorage,
//         storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
//       },
//       system: {
//         loggerOptions: {
//           loggerCallback,
//           logLevel: LogLevel.Info,
//           piiLoggingEnabled: false
//         }
//       }
//     });
//   }

@NgModule({
    declarations: [
        AuthComponent,
        AlertComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        AuthRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
    ],
    entryComponents: [AlertComponent],
})

export class AuthModule {
}