import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

import { AuthenticationService } from '../auth/_services/authentication.service';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './reset.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./reset.component.scss']
})

export class ResetComponent implements OnInit {
    encrytedData;
    email;
    session;
    showPass = false;
    showConPass = false;
    form: any;

    constructor(
        private auth: AuthenticationService,
        private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params =>
            this.encrytedData = params['session']
        );
    }

    ngOnInit() {
        this.initForm();
        this.auth.verify(this.encrytedData).subscribe((response) => {
            if (response === false) {
                this.auth.swalNotification('error', 'Error', 'Invalid session. Please try again', false).then((result) => {
                    if (result.value) {
                        this.auth.router.navigate(['/login'])
                    }
                })
            } else {
                let x = this.auth.decryption(this.encrytedData)
                this.email = x.email
                this.session = x.uuid
            }
        })
    }

    initForm() {
        this.form = new FormGroup({
            newPassword: new FormControl(null, [
                Validators.required,
                // Validators.minLength(6)
            ]),
            newPasswordAgain: new FormControl(null, [Validators.required, this.isNewPasswordsSame()])
        })
    }

    isNewPasswordsSame(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            let isInvalid = true;
            if (this.form && this.form.get('newPassword').value === this.form.get('newPasswordAgain').value) {
                isInvalid = false
            }
            return isInvalid ? { 'doNotMatch': true } : null;
        }
    }

    showPassword() {
        this.showPass = !this.showPass;
    }

    showConfirmPassword() {
        this.showConPass = !this.showConPass
    }

    resetPassword() {
        if (this.form.invalid) {
            this.auth.markFormGroupTouched(this.form);
            return;
        }
        let data = {
            password: CryptoJS.HmacSHA256(this.form.value.newPasswordAgain, '652ahscamHtset').toString(),
            email: this.email,
            session: this.session
        }
        this.auth.showLoader();
        this.auth.resetPassword(data).subscribe((response) => {
            if (response) {
                this.auth.hideLoader();
                this.auth.swalNotification('success', 'Success', 'Password reset successfully', false).then((result) => {
                    if (result.value) {
                        this.auth.router.navigate(['/login'])
                    }
                })
            }
        }, (err) => {
            this.auth.hideLoader();
        })

    }

}