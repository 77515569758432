import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";

import { AppService } from "./app.service";
import { ServicesService } from "./services.service";
import { LocationService } from "./location.service";
import { ExcelService } from "./excel.service";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [],
  declarations: [],
  providers: [
    AppService,
    ServicesService,
    LocationService,
    CookieService,
    ExcelService,
  ],
})
export class ServicesModule {}
