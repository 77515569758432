<div
  class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
  id="m_login">
  <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
    <div class="m-stack m-stack--hor m-stack--desktop">
      <div class="m-stack__item m-stack__item--fluid">
        <div class="m-login__wrapper">
          <div class="m-login__logo">
            <a>
              <img class="logo" src="./assets/logo.png" />
            </a>
          </div>
          <div class="m-login__signin">
            <div class="m-login__head">
              <h3 class="m-login__title">
                {{ "Sign In" | translate }}
              </h3>
            </div>

            <ng-template #alertSignin></ng-template>

            <!-- *ngIf="!env.production" -->
            <!-- <form
              (ngSubmit)="f.form.valid && signin()"
              #f="ngForm"
              class="m-login__form m-form"
              action=""
            >
              <ng-template #alertSignin></ng-template>
              <div class="form-group m-form__group">
                <input
                  class="form-control m-input"
                  type="text"
                  placeholder="{{ 'Code' | translate }}"
                  name="code"
                  [(ngModel)]="model.code"
                  #code="ngModel"
                  autocomplete="off"
                  data-cy="login"
                  (change)="checkUsername()"
                />
                <span class="text-danger" *ngIf="codeError">
                  This field is required!
                </span>

                <div class="m-input-icon m-input-icon--right">
                  <input
                    type="{{ loginShowPass ? 'text' : 'password' }}"
                    class="form-control m-input"
                    type="password"
                    placeholder="{{ 'Password' | translate }}"
                    name="password"
                    [(ngModel)]="model.password"
                    #password="ngModel"
                    autocomplete="off"
                    data-cy="password"
                    (change)="checkPassword()"
                  />

                  <span class="m-input-icon__icon m-input-icon__icon--right">
                    <span
                      class="cursor"
                      (click)="loginShowPass = !loginShowPass"
                    >
                      <i *ngIf="loginShowPass" class="la la-eye-slash"></i>
                      <i *ngIf="!loginShowPass" class="la la-eye"></i>
                    </span>
                  </span>
                </div>

                <span class="text-danger" *ngIf="passwordError">
                  This field is required!
                </span>
              </div>
              <button
                data-cy="sign-in"
                [disabled]="loading"
                [ngClass]="{
                  'm-loader m-loader--right m-loader--light': loading
                }"
                id="m_login_signin_submit"
                class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air signin-btn"
              >
                {{ "Sign In" | translate }}
              </button>
            </form> -->
          </div>

          <button (click)="signinMicrosoft()" data-cy="sign-in" [disabled]="loading"
            [ngClass]="{ 'm-loader m-loader--right m-loader--light': loading }" id="m_login_signin_submit_microsoft"
            class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air microsoft-btn">
            {{ "Sign In With Microsoft" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1 m-login__content boxShadow"
    style="
      background-image: url(./assets/app/media/img/bg/bg-4.jpg);
      position: relative;
    ">
    <div class="m-grid__item m-grid__item--middle">
      <h3 class="m-login__welcome">Your trusted Business IT Partner.</h3>
      <p class="m-login__msg">
        We aspire to be Asia’s most innovative retail solution provider and a
        trusted IT partner of SMEs.
      </p>
    </div>
    <div class="company w-100 m--hidden-tablet-and-mobile">
      <ng-container *ngTemplateOutlet="companyInfo"></ng-container>
    </div>
  </div>
</div>

<ng-template #companyInfo>
  <p>7 Harrison Rd, #05-01, Singapore 369650</p>
  <p>Office (65) 63846598 | www.flex-solver.com.sg</p>
  <p style="font-size: 0.85rem">
    &copy; {{ year }}. All Rights Reserved by Flex-Solver.
  </p>
</ng-template>

<!-- Forget Password - Depreciated -->
<!-- <div
  class="modal fade show"
  id="forget_modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  data-backdrop="static"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "Forgotten Password ?" | translate }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="resetModal()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div
            class="form-group"
            [ngClass]="{ 'has-danger': validEmail === false }"
          >
            <label class="col-form-label">{{
              "Enter your email to reset your password" | translate
            }}</label>
            <input
              type="email"
              class="form-control"
              placeholder="{{ 'Email' | translate }}"
              [(ngModel)]="resetEmail"
              [ngModelOptions]="{ standalone: true }"
              [ngClass]="{ 'has-danger': validEmail === false }"
              (ngModelChange)="validateEmail()"
            />
            <span
              class="form-control-feedback has-danger"
              *ngIf="validEmail === false"
            >
              {{ "Invalid email format" | translate }}
            </span>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air"
          [disabled]="!validEmail"
          (click)="forgetPassword()"
        >
          {{ "Reset" | translate }}
        </button>
      </div>
    </div>
  </div>
</div> -->