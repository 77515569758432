import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic([
    { 
        provide: MSAL_CONFIG, 
        useValue: {
            "auth": {
                "clientId": 'a088ba75-8630-4651-8097-855ef4a2b188',
                "authority": `https://login.microsoftonline.com/10fd0a64-938d-47cb-b816-2e29332b1a0d`,
                // "validateAuthority": true,
                "redirectUri": formatRedirectUri,
                "postLogoutRedirectUri": formatRedirectUri
                // "navigateToLoginRequestUrl": true
            },
            "cache": {
                "cacheLocation": "localStorage",
                "storeAuthStateInCookie": isIE, // set to true for IE 11
            }
        }
    },
    { 
        provide: MSAL_CONFIG_ANGULAR, 
        useValue: {
            "popUp": !isIE,
            "consentScopes": [
                'user.read',
                'openid',
                'profile',
            ],
            "unprotectedResources": [],
            "protectedResourceMap": [
                ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                ['https://graph.microsoft.com/v1.0/users', ['user.read.all']]
            ],
            "extraQueryParameters": { }
        }
    }
    ])
    
    .bootstrapModule(AppModule)
    .catch(err => (err));

    function formatRedirectUri(){
        let redirectUri = window.location.href.replace(window.location.hash, '');
        if(redirectUri.lastIndexOf("/") === redirectUri.length-1){
            redirectUri = redirectUri.substring(0, redirectUri.length-1);
        }
        return redirectUri;
    }