const server = 'https://voffice.flex-solver.app/_nestjs';
const socketServer = 'https://voffice.flex-solver.app';
const socketOpts = { path: '/_nestjs/socket.io' };

// const server = 'https://voffice.flex-solver.app/_e'
// const socketServer = 'https://voffice.flex-solver.app';
// const socketOpts = { path: '/_e/socket.io' };

export const environment = {
    production: true,
    serverEndPoint: server,
    socketServer,
	socketOpts
};
