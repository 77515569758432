import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ResetComponent } from "./reset/reset.component";

const routes: Routes = [
  { path: "login", loadChildren: "./auth/auth.module#AuthModule" },
  { path: "reset-password", component: ResetComponent },
  { path: "", redirectTo: "index", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
